<template>
    <Modal v-model="addFormVisible" title="添加数据字典" ref="addModal" :loading="addLoading" :mask-closable="false">
        <Form ref="addForm" :model="addForm" :rules="addFormRules" label-colon :label-width="75">
            <FormItem label="名称" prop="name">
                <Input type="text" show-word-limit :maxlength="50" v-model="addForm.name" placeholder="请输入" clearable/>
            </FormItem>
            <FormItem label="父ID" prop="parentId">
                <tree-select noOptionsText="暂无数据" :options="parentArrays" :show-count="true" placeholder="请选择"
                             v-model="addForm.parentId" :defaultExpandLevel="Infinity" :isDefaultExpanded="true"/>
            </FormItem>
            <FormItem label="序号" prop="serialNumber" placeholder="请输入">
                <Input placeholder="请输入" v-model.trim="addForm.serialNumber"/>
            </FormItem>
            <FormItem label="备注" prop="remark" placeholder="请输入">
                <Input type="textarea" placeholder="请输入" show-word-limit :maxlength="500" v-model="addForm.remark"
                       :autosize="{minRows: 3, maxRows: 5}"/>
            </FormItem>
        </Form>

        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="primary" @click="addSubmit">确定</Button>
        </div>
    </Modal>
</template>
<script>
import {reqAddDictionary} from '../../../api/system-api';
import TreeSelect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
    name: "addDictionary",
    props: {
        value: {
            type: Boolean,
            default: false
        },
        parentArrays: {
            type: Array
        }
    },
    components: {
        TreeSelect
    },
    data() {
        return {
            addForm: {
                id: "",
                name: '',
                code: '',
                remark: '',
                serialNumber: null,
                parentId: null
            },
            addFormVisible: this.value,
            addLoading: false,
            addFormRules: {
                name: [
                    {required: true, message: '请输入名称', trigger: 'blur'},
                    {min: 1, max: 50, message: '请输入名称', trigger: 'blur'},
                ],
                parentId: [
                    {required: true, type: "number", message: '请选择父级', trigger: 'change'},
                ],
                serialNumber: [
                    {min: 1, max: 4, message: '请输入小于9999的数字', trigger: 'blur'},
                    {pattern: /^[1-9]\d*$/, message: '该字段为整数', trigger: 'blur'},
                    {pattern: /^(?!(\s+$))/, message: '不能以空格开头', trigger: 'blur'},
                ],
                remark: [
                    {message: '请输入备注', trigger: 'blur'},
                    {min: 1, max: 500, message: '请输入小于500个字符的描述', trigger: 'blur'}
                ],
            },
        }
    },
    methods: {
        //添加
        addSubmit: function () {
            this.$refs['addForm'].validate((valid) => {
                if (valid) {
                    this.addLoading = true;
                    let para = Object.assign({}, this.addForm);
                    reqAddDictionary(para).then(res => {
                        this.addLoading = false;
                        if (res.data.code === 1) {
                            this.$Message.success(res.data.msg);
                            // 提交表单数据成功则关闭当前的modal框
                            this.closeModal(false);
                            this.$emit('goReset');
                        } else {
                            this.closeModal(true);
                            this.$Message.error(res.data.msg);
                        }
                    }).catch(() => {
                        this.addLoading = false;
                        this.closeModal(false);
                    })
                }
            });
        },
        closeModal(val) {
            this.$emit('input', val);
        },

    },
    mounted() {
    },
    watch: {
        value(val) {
            this.addFormVisible = val;
        },
        addFormVisible(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
                this.$refs['addForm'].resetFields();
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    }
}
</script>
